import {useEffect, useRef, useState} from "react";
import Script from "next/script";

import useConfig from "hooks/useConfig";
import useTheme from "hooks/useTheme";
import {getLocalAuthToken} from "utils/auth";
import useFrozenWindowVariable from "hooks/useFrozenWindowVariable";
import useIsAiAssistantEnabled from "hooks/useIsAiAssistantEnabled";
import {logger} from "utils/logger";

import AiOnboardingPopover from "./popover/AiOnboardingPopover";
import AiToastIncidents from "./toast/AiToastIncidents";

const WINDOW_ONBOARDED_KEY = "__XDR_AI_ASSISTANT_ONBOARDING_COMPLETED__";
const AI_ONBOARDING_COMPLETED_EVENT = "AI FW Assistant Launch From Onboarding";
const INTERNAL_ONBOARDING_COMPLETED_EVENT = "ai-onboarding-complete-internal";

export default function AiAssistant({tooltipTimeout = 2500}) {
  const {config} = useConfig();
  const {apis} = config;
  const {theme} = useTheme();
  const componentRef = useRef(null);

  const [userOnboarded, setUserOnboarded] = useState(
    Boolean(localStorage.getItem("aiAssistant.onboardingComplete")), // TODO: AI team should send an actual value from the database for whether the user has been onboarded or not
  );

  const [initialized, setInitialized] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const isEnabled = useIsAiAssistantEnabled();
  const colorTheme = theme === "dusk" ? "dark" : "light";

  const frozenWindowVariable = useFrozenWindowVariable(
    WINDOW_ONBOARDED_KEY,
    Boolean(isEnabled && userOnboarded),
    isEnabled !== null, // Do not set global window variable until we know if AI is enabled
  );

  /**
   * @todo replace this `useEffect` with a subscription to the @xdr-ui/event-client-ai-assistant package
   *
   * ```
   * useAggregatedEventCallback("userOnboarded", () => {
   *  frozenWindowVariable.value = true;
   * });
   */
  // Sets a global window variable when the user has completed onboarding
  // This can be used by other microfrontends' business logic
  useEffect(() => {
    const updateGlobalOnboardingVariable = () => {
      frozenWindowVariable.value = true;
    };

    window.addEventListener(
      INTERNAL_ONBOARDING_COMPLETED_EVENT,
      updateGlobalOnboardingVariable,
    );

    return () => {
      window.removeEventListener(
        INTERNAL_ONBOARDING_COMPLETED_EVENT,
        updateGlobalOnboardingVariable,
      );
    };
  }, [frozenWindowVariable]);

  // Displays the tooltip after the AI assistant has been initialized
  useEffect(() => {
    let timeout;
    if (initialized) {
      timeout = setTimeout(() => {
        setTooltipOpen(true);
      }, tooltipTimeout);
    }
    return () => clearTimeout(timeout);
  }, [initialized, tooltipTimeout]);

  // Keeps the theme of the AIAssistant consistent with the rest of the app (#646)
  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    let themeContainer =
      componentRef?.current?.container?.querySelector("[data-cds-theme]");
    themeContainer?.setAttribute("data-cds-theme", `magnetic-${colorTheme}`);
  }, [isEnabled, colorTheme]);

  return (
    isEnabled && (
      <>
        <AiOnboardingPopover
          open={tooltipOpen && !userOnboarded}
          onCancelClick={() => setTooltipOpen(false)}
          onContinueClick={() => {
            // opens the ai assistant
            componentRef?.current?.container?.querySelector("button")?.click();
            setTooltipOpen(false);
          }}
        >
          <div style={{marginRight: 4}}>
            {/* This is the mounting point of the AI Assistant */}
            <ai-assistant ref={componentRef} />
          </div>
        </AiOnboardingPopover>

        {userOnboarded && initialized && <AiToastIncidents />}

        <Script
          src={getAiScriptSrc(apis.ai.cdn)}
          strategy="lazyOnload"
          onError={(err) => {
            logger.error("Failed to load AiAssistant!", err);
          }}
          onLoad={() => {
            // gives async header buttons enough time to load
            setTimeout(() => {
              setInitialized(true);
            }, tooltipTimeout);

            componentRef.current.config = {
              apiVersion: "v2",
              appTheme: "xdr",
              auth: {
                access_token: getLocalAuthToken(),
              },
              colorTheme,
              callbacks: {
                getPluginSrc: ({isAppPlugin, isPluginDev}) => {
                  // When using XDR UI for AI Assistant local dev env
                  // Not related to XDR shell dev
                  if (isAppPlugin && isPluginDev && process.env.DEV_HASH) {
                    return "http://localhost:4010/ai-assistant-plugin-xdr.js";
                  }
                  return "";
                },
                trackEvent: (aiEvent) => {
                  if (aiEvent === AI_ONBOARDING_COMPLETED_EVENT) {
                    // We have a closure on local component state inside this
                    // callback, so we dispatch our own event that this component
                    // subscribes to so that it can use the updated state values
                    window.dispatchEvent(
                      new Event(INTERNAL_ONBOARDING_COMPLETED_EVENT),
                    );
                    // `userOnboarded` is read from local storage so it won't force this component
                    // to re-render when the AI assistant sets its value to "true". Instead we get
                    // the initial value from local storage and update this state value when this
                    // event is received to force this component to re-render (force re-renders
                    // any notifications or tooltips that depend on this state value). On
                    // subsequent visits to the page, `userOnboarded` will be read from local
                    // storage and initialize to `true`.
                    setUserOnboarded(true);
                  }
                },
              },
              edgeApiUrl: "/api/ai/gateway",
              plugins: ["xdr"],
            };
          }}
        />
      </>
    )
  );
}

function getAiScriptSrc(config) {
  if (!config) {
    throw new Error("No config found!");
  }

  const {host, file} = config;

  if (process.env.REGION.startsWith("INT")) {
    // allow script overrides on INT
    const scriptHost = localStorage.getItem("aiAssistant.scriptHost") || host;
    const scriptFile = localStorage.getItem("aiAssistant.scriptFile") || file;
    return `${scriptHost}/${scriptFile}`;
  } else {
    // return values from config on other envs
    return `${host}/${file}`;
  }
}
